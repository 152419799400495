import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/css-clearing-floats",
  "date": "2015-02-01",
  "title": "CSS – CLEARING FLOATS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "A common problem with float-based layouts is that the floats' container doesn't want to stretch up to accommodate the floats. If you want to add, say, a border around all floats (ie. a border around the container) you'll have to command the browsers somehow to stretch up the container all the way."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Clearing Floats`}</h2>
    <p>{`Floated items can be taller than non-floated content`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7307/16414108831_3d202f370a_o.png",
        "alt": "Clearing Floats"
      }}></img></p>
    <p>{`Clearing is necessary if all children are floating`}</p>
    <p>{`Common float-clearing methods:`}</p>
    <ul>
      <li parentName="ul">{`Clear with a subsequent element`}</li>
      <li parentName="ul">{`Manual clearing`}</li>
      <li parentName="ul">{`The clearfix`}</li>
    </ul>
    <p>{`clear: left / right / both`}</p>
    <h3>{`Clear with a subsequent element`}</h3>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
    <img src="ski.jpg" alt="Skiing!" />
    To successfully ski, simply do not fall.
</div>
<div class="intro">
    Whee!
</div>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`img {
    float: left;
}
.intro {
    clear: both;
}
`}</code></pre>
    <h3>{`Clear with a subsequent element`}</h3>
    <p>{`This requires sequence to stay intact - breaks if things move.
Background / border do not extend.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8645/15793452054_d6d82a0298_o.png",
        "alt": "Clear with a subsequent element"
      }}></img></p>
    <h3>{`Manual clearing`}</h3>
    <p>{`This requires an empty element`}</p>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
    <img src="ski.jpg" alt="Skiing!" />
    To successfully ski, simply do not fall.
    <div class="clear"></div>
</div>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.clear {
    clear: both;
}
`}</code></pre>
    <h3>{`The clearfix`}</h3>
    <p>{`The clearfix was originally developed by Tony Aslett, and then refined by Nicholas Gallagher.
When used on the parent, the children will be self-cleared.`}</p>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="group">
    <img src="ski.jpg" alt="Skiing!" />
    To successfully ski, simply do not fall.
</div>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.group:before,
.group:after {
    content: "";
    display: table;
}
.group:after {
    clear: both;
}
.group {
    zoom: 1; /* IE6&7 */
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      